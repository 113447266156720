import React from 'react'
import { DashboardMetricasView } from './DashboardMetricasView'

function DashboardMetricas() {
  return (
    <DashboardMetricasView/>
  )
}

export { DashboardMetricas }
