import React from 'react'
import { PageRegisterView } from './PageRegisterView'

function PageRegister() {
  return (
    <PageRegisterView />
  )
}

export { PageRegister }
