import React from 'react'
import { PageForgetPasswordView } from './PageForgetPasswordView'

function PageForgetPassword() {
  return (
    <PageForgetPasswordView />
  )
}

export { PageForgetPassword }
