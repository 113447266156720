import React from 'react'
import { PageRegisterAdvisorView } from './PageRegisterAdvisorView'

function PageRegisterAdvisor() {
  return (
    <PageRegisterAdvisorView/>
  )
}

export { PageRegisterAdvisor }
