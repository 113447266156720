import React from 'react'
import { PageAboutSystemView } from './PageAboutSystemView'

function PageAboutSystem() {
  return (
    <PageAboutSystemView/>
  )
}

export { PageAboutSystem }
